import React, { useEffect } from 'react'
import Header from "../../components/header"
import HeaderMenu from "../../components/header-menu"
import Footer from "../../components/footer"
import { Link } from 'gatsby'
import { navigate } from "gatsby"
import { getUser,logout } from "../../services/auth"
import classNames from "classnames"


const MenuVisites = props => {
  console.log("menu visites")
  /* Nivell: 
      1. Access a tot
      2. Acces a sols destete engreix
      3. Acces a sols destete mares 
  */
  function signOut(event){
    event.preventDefault();
      logout()
      navigate("/login")        
  }
  const handleClickDown = (e) => {
    e.preventDefault();
    signOut()
  }
  useEffect( () => {
    if (!getUser() || getUser().nivell.veterinari!==1) {
        navigate("../../mainMenu") 
    }
 
    }, []);
  return (
    <span>
       
      <div className="granjatec-wrapper">
        <Header/>
        <HeaderMenu />
        <div>
      </div>
        <div className="main-container">
          <section className="section choose-opt">
              {getUser() && getUser().nivell.veterinari? (
                  <Link className="btn-primary" to="/veterinaris/visita">Visita</Link>
                ):
                <span/>
              }
              {getUser() && getUser().nivell.veterinari? (
                  <Link className="btn-primary" to="/veterinaris/informes" >Històric Informes</Link>
                ):
                  <span/>
              }
            
            <button onClick={handleClickDown} className={ classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
              <span>Prev</span>
            </button>
            
          </section>
        </div>
        <Footer/>
      </div>
      </span>
  )

}

export default MenuVisites